import { useEffect } from 'react';

const useResize = (setIsMobile) => {
  useEffect(() => {
    // Verificar el tamaño del viewport al montar
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    // Ejecutar inmediatamente para establecer el estado inicial
    handleResize();

    // Agregar el listener para cambios de tamaño del viewport
    window.addEventListener('resize', handleResize);

    // Limpiar el listener al desmontar
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setIsMobile]); // Agregar `setIsMobile` como dependencia para evitar problemas de referencia
};

export default useResize;
