import { useChatMenu } from "../../../ChatWindow/ChatMenuContext";
import styles from "./MessageListSearch.module.css"

export default function MessageListSearch() {
  const { handleChatSearch } = useChatMenu();

    return (
      <div className={styles.messageSearch}>
        <input type="text" placeholder="Buscar..."  onChange={(e) => handleChatSearch( e.target.value.trim())}/>

      </div>
    );
  }