import { VscPinned } from 'react-icons/vsc';
import MessageListCard from './MessageListCard/MessageListCard';
import styles from './MessageList.module.css'; 
import { TbMessage2 } from 'react-icons/tb';
import { useChatMenu } from '../../ChatWindow/ChatMenuContext';
import { formatTimestampToTime } from '../../utils/formatTimeStamp';
import { Paginator } from 'primereact/paginator';
import { useState} from 'react';
import { collection, getDocs, limit, onSnapshot, orderBy, query, startAfter } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase';
import CustomButton from '../../customComponents/CustomButton';
import { useLoaderContext } from '../../../../contexts/LoaderContext/LoaderContext';

const MessageList = () => {

  const {
    filteredChats, setFilteredChats, lastDoc, setLastDoc, setChats,isWidgetView,
    numberChatDocuments , setNumberChatDocuments,
    unsubscribeOrderStateSnapshot, setUnsubscribeOrderStateSnapshot,
   } = useChatMenu();



  const foundLastMessage = (chat) => {
    const lastMessage = chat?.messages?.[chat?.messages?.length - 1];
    let lastMessageText = lastMessage?.content_text ? lastMessage?.content_text : "";
    switch (lastMessage?.type) {
      case "image":
        lastMessageText = lastMessage?.content_text === "" ? "imagen" : lastMessageText;
        break;
      case "audio":
        lastMessageText = "audio";
        break;
      case "video":
        lastMessageText = lastMessage?.content_text === "" ? "video" : lastMessageText;
        break;
      case "document":
        lastMessageText = lastMessage?.content_text === "" ? "documento" : lastMessageText;
        break;
      case "text":
        lastMessageText = lastMessage?.content_text;
        break;
      default:
        break;
    }
    return lastMessageText;
  };
  const {setGlobalLoading} = useLoaderContext();
  const [hasMore, setHasMore] = useState(true);
  console.log("numberChatDocuments", numberChatDocuments)
  const onPageChange = async () => {
    setGlobalLoading(true);
    if (unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function") {
      unsubscribeOrderStateSnapshot();
      setUnsubscribeOrderStateSnapshot(null);
    }
    const newRows = 1;
    const chatsCollection = collection(db, "chats");
    console.log("bandera1")
    // Verificar si hay más documentos antes de avanzar
    const verificationQuery = query(
      chatsCollection,
      orderBy("lastUpdate", "desc"),
      startAfter(lastDoc), // Comenzar después del último documento
      limit(numberChatDocuments + newRows) // Intentar cargar más de lo actual
    );
    console.log("bandera2")
  
    const verificationSnapshot = await getDocs(verificationQuery);
    console.log("bandera3")
  
    // Si no hay documentos nuevos, no cambia la página
    if (verificationSnapshot.empty) {
      console.log("No more documents to load in this direction.");
      setHasMore(false); // Indicar que no hay más documentos
      return;
    }
    console.log("bandera4")
  
    if (hasMore) {
      // Crear consulta acumulativa para añadir más datos
      const chatsQuery = query(
        chatsCollection,
        orderBy("lastUpdate", "desc"),
        limit(numberChatDocuments + newRows) // Aumentar el límite de carga
      );
      console.log("bandera5")
  
      const unsubscribe = onSnapshot(
        chatsQuery,
        (querySnapshot) => {
          const chatMessagesData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
  
          // Actualiza `lastDoc` y `firstDoc` dependiendo de la dirección de la paginación
          if (querySnapshot.docs.length > 0) {
            setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]); // Último documento para la siguiente página
          }
  
          // Verifica si no hay más datos para paginar
          if (querySnapshot.size < numberChatDocuments) {
            setHasMore(false);
          }
          console.log("bandera6")
  
          // Acumular documentos en lugar de reemplazar
          setChats(chatMessagesData);
          setFilteredChats(chatMessagesData);
          setGlobalLoading(false);

        },
        (error) => {
          console.error("Error fetching paginated chats:", error);
        }
      );
      console.log("bandera7")
  
      setNumberChatDocuments((prev) => prev + newRows); // Aumentar el límite dinámicamente
      setUnsubscribeOrderStateSnapshot(() => unsubscribe);
      setGlobalLoading(false)
  
      return () => unsubscribe();
    }
    setGlobalLoading(false);

  };
  
  
  

  return (
    <div className={styles.messageListContainer}>
      <div>
        {filteredChats?.some(chat => chat.pinned) && (
          <div className={isWidgetView ? styles.sectionMessagesListWidget: styles.sectionMessagesList}>
            <p className={styles.sectionMessageText}>
              <VscPinned className={styles.icon} />
              Mensajes fijos
            </p>
            <ul className={styles.messageList}>
              {filteredChats
                ?.filter(chat => chat.pinned)
                .map((chat, index) => {
                  const lastMessage = foundLastMessage(chat);
                  const lastMessageInDate = formatTimestampToTime(chat?.lastInMessageDate);
                  return (
                    <MessageListCard
                      isRead={chat?.read}
                      profile_image={chat?.profile_image}
                      key={index}
                      last_message_in_Date={lastMessageInDate}
                      wa_id={chat.id}
                      last_message={lastMessage}
                    />
                  );
                })}
            </ul>
          </div>
        )}
      
        {filteredChats?.some(chat => !chat.pinned) > 0 && (      
          <div className={isWidgetView ? styles.sectionMessagesListWidget: styles.sectionMessagesList}>
            <p className={styles.sectionMessageText}>
              <TbMessage2 className={styles.icon} />
              Todos los mensajes
            </p>
            <ul className={styles.messageList}>
              {filteredChats
                ?.filter(chat => !chat.pinned).map((chat, index) => {
                const lastMessage = foundLastMessage(chat);
                const lastMessageInDate = formatTimestampToTime(chat?.lastInMessageDate);
                return <MessageListCard  isRead={chat?.read} profile_image={chat?.profile_image} last_message_in_Date={lastMessageInDate} key={index} wa_id={chat.id} last_message={lastMessage} />;
              })}
            </ul>
          </div>
        )}
      </div>
     

      <div className={styles.paginatorFooter}>
        <CustomButton style={{width: "100%"}} onClick={onPageChange} label='Cargar mas' />
      </div>
    </div>
  );
};

export default MessageList;
