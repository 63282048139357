/* eslint-disable react/prop-types */
import styles from "./ChatWindow.module.css";
import {
  ChatAudioMessage,
  ChatDocumentMessage,
  ChatImageMessage,
  ChatMessageText,
  ChatVideoMessage,
} from "./ChatMessagges/ChatMessages";
import filterMostRecentStatuses from "../utils/filterMostRecentStatuses";
import { useEffect, useState } from "react";
import { useChatMenu } from "./ChatMenuContext";
import { Timestamp } from "firebase/firestore";

const ChatMessagesList = () => {
    const {  chats, selectedChat, setSelectedChat, messagesEndRef, isWidgetView, selectedWaId} = useChatMenu();


   
  // Agrupa mensajes por fecha (YYYY-MM-DD)
  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      // Verifica si la fecha es un Timestamp de Firebase
      if (message.creationDate instanceof Timestamp) {
        // Convierte el Timestamp a un objeto Date
        message.creationDate = message.creationDate.toDate();
      } else if (!(message.creationDate instanceof Date)) {
        // Si no es ni Timestamp ni Date, omite este mensaje
        console.warn("Mensaje con fecha inválida:", message);
        return groups;
      }
  
      // Extrae la fecha en formato YYYY-MM-DD
      const dateKey = message.creationDate.toISOString().split("T")[0];
  
      const profileImage =  message?.origen === "in" ? selectedChat?.profile_image :  "admin.image"
      // Agrupa los mensajes por la clave de fecha
      if (!groups[dateKey]) groups[dateKey] = [];
      groups[dateKey].push({...message, profile_image: profileImage});
  
      return groups;
    }, {});
  };

  const [groupedMessages, setGroupedMessages] = useState(groupMessagesByDate(selectedChat?.messages || [] ))
  const [chatStatuses, setChatStatuses] = useState(groupMessagesByDate(selectedChat?.statuses || [] ));


  const formatAndValidateDate = (time) => {
    if (typeof time !== "string") return "Fecha inválida"; // Valida que sea una cadena
  
    // Forzar interpretación local
    const [year, month, day] = time.split("-").map(Number);
    const parsedDate = new Date(year, month - 1, day); // Meses son 0 indexados
  
    // Verifica si la fecha es válida
    if (isNaN(parsedDate)) return "Fecha inválida";
  
    const result = parsedDate.toLocaleDateString("es-CO", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return result;
  };

  // console.log("groupedMessages",groupedMessages)
  // console.log("chatStatuses", chatStatuses)

  const findRepliyingMessage = (replyingId) => {
    const foundedMessageRepliying = selectedChat?.messages?.find((message) =>  message?.id === replyingId);
    // console.log("foundedMessageRepliying", foundedMessageRepliying)
    const typeRepliying =  foundedMessageRepliying?.type ? foundedMessageRepliying?.type : null
    const contentRepliying = foundedMessageRepliying?.content_url ? foundedMessageRepliying.content_url : null
    const contentTextReplying = foundedMessageRepliying?.content_text ? foundedMessageRepliying.content_text : null
    return {typeRepliying, contentRepliying, contentTextReplying}
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };


  useEffect(() => {
    // console.log("Se renderizo ChatMessagesList", chats, isWidgetView, selectedChat, selectedWaId )
    const waId = selectedChat?.wa_id || selectedWaId
    if(waId ) {
      const foundedChat = chats.find((message) => message?.wa_id ===  waId )
      setSelectedChat(foundedChat)

      const orderedMessages = groupMessagesByDate(foundedChat?.messages || [] );
      setChatStatuses(foundedChat?.statuses || [])
      setGroupedMessages(orderedMessages)
    }
    scrollToBottom()
  
  }, [chats, selectedChat]);

  return (

    <div className={styles.chatMessagesList}>
      {Object.keys(groupedMessages).map((dateKey) => (
        <div key={dateKey} className={styles.dateGroupMessageContainer}>
          {/* Contenedor de la fecha */}
          <div className={styles.chatDate}>
            <p>{formatAndValidateDate(dateKey)}</p>
          </div>

          {/* Renderizar mensajes del grupo */}
          {groupedMessages[dateKey].map((message) => {
            const isSent = message.origen === "out";
            const contentUrl = message.content_url;
            const messageStatuses = filterMostRecentStatuses(chatStatuses, message?.id);
            const isReplying = message?.context?.id ? true : false;
            const replyingId = isReplying ? message?.context?.id : null;

            const replyingData = replyingId
              ? findRepliyingMessage(replyingId)
              : { typeRepliying: null, contentRepliying: null, contentTextReplying: null };

            const { typeRepliying, contentRepliying, contentTextReplying } = replyingData;

            if (!message || (isReplying && !replyingData)) {
              return null;
            }

            switch (message.type) {
              case "text":
                return (
                  <ChatMessageText
                    status={messageStatuses}
                    key={message.id}
                    messageId={message.id}
                    avatarSrc={message.profile_image}
                    username={message.username}
                    time={message.creationDate}
                    type={message.type}
                    messageText={message.content_text}
                    isSent={isSent}
                    isReplying={isReplying}
                    typeRepliying={typeRepliying}
                    messageTextRepliying={contentTextReplying}
                    imgSrcRepliying={contentRepliying}
                    videoSrcRepliying={contentRepliying}
                    idMessageRepliying={replyingId}
                    audioDuration={message.audioDuration}
                  />
                );
              case "image":
                return (
                  <ChatImageMessage
                    status={messageStatuses}
                    key={message.id}
                    messageId={message.id}
                    avatarSrc={message.profile_image}
                    username={message.username}
                    time={message.creationDate}
                    messageText={message.content_text}
                    imageSrc={contentUrl}
                    type={message.type}
                    isSent={isSent}
                    isReplying={isReplying}
                    typeRepliying={typeRepliying}
                    imgSrcRepliying={contentRepliying}
                    videoSrcRepliying={contentRepliying}
                    messageTextRepliying={contentTextReplying}
                    idMessageRepliying={replyingId}
                    audioDuration={message.audioDuration}
                  />
                );
              case "audio":
                return (
                  <ChatAudioMessage
                    status={messageStatuses}
                    key={message.id}
                    messageId={message.id}
                    avatarSrc={message.profile_image}
                    username={message.username}
                    type={message.type}
                    time={message.creationDate}
                    typeRepliying={typeRepliying}
                    isReplying={isReplying}
                    audioSrc={contentUrl}
                    isSent={isSent}
                    messageTextRepliying={contentTextReplying}
                    contentRepliying={contentRepliying}
                    idMessageRepliying={replyingId}
                    audioDuration={message.audioDuration}
                  />
                );
              case "video":
                return (
                  <ChatVideoMessage
                    status={messageStatuses}
                    key={message.id}
                    messageId={message.id}
                    avatarSrc={message.profile_image}
                    username={message.username}
                    time={message.creationDate}
                    messageText={message.content_text}
                    type={message.type}
                    videoSrc={contentUrl}
                    isSent={isSent}
                    isReplying={isReplying}
                    typeRepliying={typeRepliying}
                    videoSrcRepliying={contentRepliying}
                    imgSrcRepliying={contentRepliying}
                    messageTextRepliying={contentTextReplying}
                    idMessageRepliying={replyingId}
                    audioDuration={message.audioDuration}
                  />
                );
              case "document":
                return (
                  <ChatDocumentMessage
                    status={messageStatuses}
                    key={message.id}
                    messageId={message.id}
                    avatarSrc={message.profile_image}
                    username={message.username}
                    time={message.creationDate}
                    messageText={message.content_text}
                    type={message.type}
                    videoSrc={contentUrl}
                    isSent={isSent}
                    isReplying={isReplying}
                    typeRepliying={typeRepliying}
                    videoSrcRepliying={contentRepliying}
                    imgSrcRepliying={contentRepliying}
                    messageTextRepliying={contentTextReplying}
                    idMessageRepliying={replyingId}
                    audioDuration={message.audioDuration}
                    documentUrl={contentUrl}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
      ))}

      {/* Elemento invisible para hacer scroll hasta el final */}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatMessagesList;
