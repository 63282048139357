
const BASE_URL = "https://us-central1-shoppex-aa709.cloudfunctions.net"
// const BASE_URL ="http://127.0.0.1:5001/shoppex-aa709/us-central1"


export const fetchFromCloudFunction = async (endpoint, body, method, contentType = "application/json") => {
    const urlCloudFuntion = `${BASE_URL}/${endpoint}`
    console.log("antes de llamar ala cloud function", urlCloudFuntion, "body", body, method)
    try {
        const fetchObj = {
            method: method,
            body
        }

        if (contentType !== "multipart/form-data") {
            fetchObj.headers = {
                'Content-Type': contentType
            }
            fetchObj.body = JSON.stringify(body) 
        }
        console.log("fetchObject", fetchObj)
        const response = await fetch(urlCloudFuntion, fetchObj);

        console.log("fetchFromCloudFunction", response);
        const status = response.status;
        console.log("status", status);
        const data = await response.json();
        return {status, data};
    } catch (error) {
        console.error('Error llamando a la Cloud Function:', error);
        throw error;
    }
}
