import {  IoChevronBackOutline } from 'react-icons/io5';
import { SlOptions } from 'react-icons/sl';
import styles from './ChatHeader.module.css'; // Asegúrate de ajustar la ruta del archivo CSS
import { MESSAGESLIST } from '../../utils/CRMconstants';
import { useChatMenu } from '../ChatMenuContext';

const ChatHeader = () => {

  const {handleSidebarSelect, selectedChat, isMobile, isWidgetView} = useChatMenu();
  
  return (
    <div className={styles.chatHeader}>
       {(isMobile || isWidgetView) && (
        <button onClick={() => handleSidebarSelect(MESSAGESLIST)}className={styles.goBackBtnIcon}>
          <IoChevronBackOutline  />
        </button>)}
      { selectedChat ? (
        <>
        <div className={styles.chatContactInfo}>
        <div className={styles.contactAvatar}>
          <img
            src={selectedChat?.profile_image || "https://randomuser.me/api/portraits/lego/1.jpg" }
            alt="contact"
          />
        </div>
        <div className={styles.contactInfo}>
          <p className={styles.chatTitle}>{selectedChat.wa_id ? selectedChat.wa_id : "Cliente" }</p>
          {/* <p className={styles.chatStatus}>escribiendo</p> */}
        </div>
      </div>

      <div className={styles.chatHeaderBtns}>
        {/* <button>
          <GoDeviceCameraVideo className={styles.icon} />
        </button>
        <button>
          <IoCallOutline className={styles.icon} />
        </button> */}
        <button>
          <SlOptions className={styles.icon} />
        </button>
      </div>
        </>
      ) : (
        <div>
          </div>
      )}
    </div>
  );
};

export default ChatHeader;
