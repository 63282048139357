/**
 * Filtra los estados para que solo traiga el más reciente de un ID específico según su timestamp.
 * @param {Array} statuses - Array de objetos con el formato { id, creationDate, ...otrosDatos }.
 * @param {string} filterId - El ID por el que se desea filtrar.
 * @returns {Object|null} - El estado más reciente para el ID especificado, o null si no se encuentra.
 */
export default function filterMostRecentStatusById(statuses, filterId) {
  if (!Array.isArray(statuses)) {
    // console.warn("El argumento proporcionado no es un array.");
    return null;
  }

  if (!filterId) {
    // console.warn("El ID proporcionado es inválido.");
    return null;
  }

  // Filtrar estados por el ID proporcionado
  const filteredStatuses = statuses.filter(status => status.id === filterId);

  if (filteredStatuses.length === 0) {
    // console.warn(`No se encontraron estados con el ID: ${filterId}`);
    return null;
  }

  // Encontrar el estado más reciente usando el campo `creationDate`
  const mostRecentStatus = filteredStatuses.reduce((latest, current) => {
    // `current.creationDate` y `latest.creationDate` son objetos Date, por lo que podemos compararlos directamente
    return current.creationDate > latest.creationDate ? current : latest;
  });

  return mostRecentStatus;
}
