import { FaPencilAlt } from "react-icons/fa"
import styles from "./MessageListHeader.module.css"
import MessageListSearch from "./MessageListSearch/MessageListSearch"
import { IoChevronBackOutline } from "react-icons/io5";
import { SIDEBARMENU } from "../../utils/CRMconstants";
import { useChatMenu } from "../../ChatWindow/ChatMenuContext";
import { useState } from "react";
import CustomModal from "../../../CustomModal/CustomModal";
import MainButton from "../../../MainButton/MainButton";
import CustomTextArea from "../../../FormComponents/CustomTextArea/CustomTextArea";

export default function MessageListHeader() {
    const {
        handleSidebarSelect,
        isMobile,
        isWidgetView,
        handleSendMessagge,
        textValue, setTextValue,
        selectedWaId, setSelectedWaId
       } = useChatMenu();

    const [visibleNewMessageModal, setVisibleNewMessageModal] = useState(false);

    const handleSendNewMessagge = async () => {
      await handleSendMessagge()
    }
    return (
        <>
            <div>
                <div className={styles.messageListHeader}>
                   {(isMobile || isWidgetView) && ( <button onClick={() => handleSidebarSelect(SIDEBARMENU)}>
                        <IoChevronBackOutline className={styles.goBackBtnIcon} />
                    </button>)}
                    <h2 className={styles.sidebarTitle}>Mensajes</h2>
                    <button className={styles.sidebarPencilBtn}
                        onClick={() => setVisibleNewMessageModal(true)}
                    >
                        <FaPencilAlt className={styles.iconSidebarPencilBtn} />
                    </button>
                </div>
                {/* Buscador de mensajes */}
                <MessageListSearch />
            </div>
          <div>
            <CustomModal
                visible={visibleNewMessageModal}
                setVisible={setVisibleNewMessageModal}
                createMode={"Enviar mensaje"}
                content={
                    <div 
                      style = {{
                        display:"Flex",
                        flexDirection:"column",
                        height:"100%",
                        justifyContent:"space-between"
                      }}>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#e0e0e0",  // Color de fondo más claro
                            width: "60px",  // Un poco más ancho para que el texto encaje bien
                            height: "40px",
                            borderRadius: "5px 0 0 5px",  // Bordes redondeados
                            fontWeight: "bold",  // Hacer el texto más visible
                            fontSize: "16px",  // Aumentar el tamaño de la fuente para mejor visibilidad
                            color: "#555",  // Color de texto más oscuro para contraste
                          }}
                        >
                          +57
                        </div>
                        <input
                          style={{
                            width: "100%",
                            height: "40px",

                            padding: "10px",  // Añadir espacio interior
                            fontSize: "16px",  // Tamaño de fuente acorde al contenedor
                            border: "1px solid #ccc",  // Borde sutil
                            borderRadius: "0 5px 5px 0",  // Bordes redondeados en la parte derecha
                            outline: "none",  // Eliminar el borde por defecto al enfocar
                            boxSizing: "border-box",  // Asegurarse de que el padding no rompa el diseño
                          }}
                          type="text"
                          placeholder="Digita el número"
                          value={selectedWaId}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Filtrar solo los dígitos numéricos
                            const numericValue = inputValue.replace(/[^0-9]/g, ''); 
                            setSelectedWaId(numericValue);  // Actualizar el estado solo con números
                          }}
                        />
                      </div>
                      <div style={{marginTop:"10px"}}>
                        <CustomTextArea 
                          onChange={(e) => setTextValue(e.target.value)} 
                          value={textValue}/>
                      </div>

                        </div>

                      <MainButton onClick={handleSendNewMessagge} label={"Enviar mensaje"}/>

                  </div>
                  // 3142256673
                }
                onClose={() => setVisibleNewMessageModal(false)}
                />
          </div>
        </>
    )
}