import styles from "./ChatWindow.module.css"

import ChatInputMessage from "./ChatInputMessage/ChatInputMessage";
import ChatHeader from "./ChatHeader/ChatHeader";
import { useChatMenu } from "./ChatMenuContext";
import { useEffect,  } from "react";
import ChatMessagesList from "./ChatMessagesList";

// eslint-disable-next-line react/prop-types
export default function ChatWindow() {

  const {  chats, responseMode, selectedChat, messagesEndRef } = useChatMenu();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // useEffect para hacer scroll hacia abajo al montar el componente
  useEffect(() => {
    // console.log("Se renderizo chat window", selectedChat)

    scrollToBottom(); // Scroll inicial
    if (responseMode === false) {
      const intervalId = setInterval(() => {
        scrollToBottom();
      }, 100); // Intentar hacer scroll cada 100 ms
      // Limpiar el intervalo después de 1 segundo
      setTimeout(() => {
        clearInterval(intervalId);
      }, 1000);
    }

  }, [chats, selectedChat]);


 
  return (
    <>
        <div className={styles.chatWindowContainer}>
          {/* Cabecera del chat */}
          <ChatHeader />
  

          <ChatMessagesList/>
          
          {/* Input para enviar mensajes */}
          <ChatInputMessage />
        </div>
    </>
  );
  }

