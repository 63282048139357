import { useEffect, useRef, useState } from 'react';
import { BsFillMicFill, BsSend } from 'react-icons/bs';
import styles from './ChatInputMessage.module.css'; // Ajusta la ruta de tu archivo CSS
import { TieredMenu } from 'primereact/tieredmenu';
import { MdDelete, MdOutlineAddCircleOutline } from "react-icons/md";
import { Button } from 'primereact/button';
import VoiceMediaRecorderBtn from './VoiceMediaRecorderBtn';
import { useChatMenu } from '../ChatMenuContext';
import TextAreaInputMessage from './TextAreaInputMessage';
import { IoIosVolumeHigh } from 'react-icons/io';
import LoaderSpinner from '../../../LoaderSpinner/LoaderSpinner';
import { FaFilePdf, FaFileWord, FaTimes } from 'react-icons/fa';
import { useShortcutNavigation } from '../../../../hooks/useShortcutNavigation';
import { Toast } from 'primereact/toast';
import { ReactMic } from 'react-mic';
import { useLoaderContext } from '../../../../contexts/LoaderContext/LoaderContext';

// eslint-disable-next-line react/prop-types
const ChatInputMessage = () => {
  const menu = useRef(null);
  const { globalLoading } = useLoaderContext();
  const {
    chatMessagesExample, 
    selectedMenuOption, 
    messageData, 
    scrollToElementById,
    responseMode, setResponseMode,
    removeImage, removeDocument, removeVideo,
    imagePreviewUrls, videoPreviewUrls,
    documentFiles,
    clearHandle,
    visibleMicrophoneHandler,
    audioUrl, voice,
    selectDocuments, selectImages, selectVideos,
    loadingMediaDevices,
    isRunning, 
    startHandle,
    onStop,
    handleSendMessagge,
    textValue, setTextValue,
    toast,
  } = useChatMenu();
  

  const [selectedShortcut, setSelectedShortcut] = useState(false);

  const [filteredShortcuts, setFilteredShortcuts] = useState([]);
  const [showShortcuts, setShowShortcuts] = useState(false);


  // const mediaRecorderRef = useRef(null);


  
  const [isDragging, setIsDragging] = useState(false); 

  const { activeIndex, setIsNavigationActive, activeShortcutRef } = useShortcutNavigation(
    filteredShortcuts,
    (selectedShortcut) => {
      setShowShortcuts(false);
      setTextValue(`${selectedShortcut.content}`); // Autocompleta el texto
    }
  );

  const textAreaRef = useRef(null); 


  // Función para manejar el menú de opciones para cada cliente en la tabla

  const optionMenu = (e) => {
    menu.current.toggle(e);
  };

  // const handleAudioRecording = async (audioBlob) => {
  //   if (!audioBlob) {
  //     console.error("audioBlob is null or undefined");
  //     return;
  //   }

  //   const reader = new FileReader();
  //   console.log("Iniciando lectura del blob:", audioBlob);

  //   reader.onloadend = () => {
  //     const base64data = reader.result;
  //     console.log("Datos base64 obtenidos:", base64data);
  //     setAudioUrl(base64data);
  //   };

  //   reader.onerror = (error) => {
  //     console.error("Error al leer el blob:", error);
  //   };

  //   reader.readAsDataURL(audioBlob);
  // };

    // Función para manejar la grabación de audio

  const handleCancelResponse = () => {
    // setSelectedMenuOption((prevState) =>  ({...prevState, option: null}));
    setResponseMode(undefined)
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true); 
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false); 
  };

  const handleClickOutside = (event) => {
    if (textAreaRef.current && !textAreaRef.current.contains(event.target)) {
      // Forzar blur del textarea
      textAreaRef.current.blur();
    }
    setIsDragging(false)
  };

  const handleShortcutSelect = (shortcut) => {
    setTextValue(`${shortcut.content} `); // Agrega el atajo seleccionado al `textarea`
    setShowShortcuts(false); // Oculta la lista de atajos
  };
  

  // Hook para manejar clics fuera del textarea
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  useEffect(() => {
    if(chatMessagesExample && selectedMenuOption?.option=== 1) {
      setResponseMode(true);
    }
  }, [selectedMenuOption])


  const menuItems = MenuItems({
    selectImages,
    selectVideos,
    selectDocuments,
  });

  
  return (
    <div className={styles.chatInputMessageWrapper}>
        {/* ventana de atajos */}
        {showShortcuts && (
          <div>
    
          {showShortcuts && (
            <div className={styles.shortcutListContainer}>
              {filteredShortcuts.length > 0 ? (
                filteredShortcuts.map((shortcut, index) => (
                  <div
                    key={index}
                    ref={index === activeIndex ? activeShortcutRef : null} // Asocia la referencia al elemento activo
                    className={`${styles.shortcutItem} ${
                      index === activeIndex ? styles.activeShortcut : ""
                    }`}
                    onClick={() => handleShortcutSelect(shortcut)}
                  >
                    <span style={{ fontWeight: "bold" }}>{shortcut.title}</span>:{" "}
                    {shortcut.content}
                  </div>
                ))
              ) : (
                <div className="tooltip-item">No hay atajos disponibles</div>
              )}
            </div>
          )}
        </div>
        )}
        {/* modo de respuesta del contenedor */}
        {responseMode === true && (
            <div className={styles.chatInputContainerResponse} onClick={() => scrollToElementById(messageData?.messageId)}>
              <div className={styles.responseHeader} >
                <span className={styles.usernameResponse}>{messageData?.username}</span>

                <p className={styles.messageText}>
                {messageData?.messageText}
                {messageData?.audioSrc && (
                  <div className={styles.audioIconContainer}>
                    <IoIosVolumeHigh className={styles.icon} /> {/* Ajusta el icono según tu preferencia */}
                    <span>Duracion {messageData?.audioDuration}</span>
                  </div>
                )}
              </p>
              </div>
              

              {(messageData?.videoSrc || messageData?.imageSrc) && (
                <div className={styles.mediaContainer}>
                  {messageData?.videoSrc && (
                    <video className={styles.thumbnail} muted>
                    <source src={messageData.videoSrc} type="video/mp4" />
                    Tu navegador no soporta el elemento de video.
                  </video>
                  )}
                  {messageData?.imageSrc && (
                    <img src={messageData.imageSrc} alt="Contenido" className={styles.thumbnail} />
                  )}
                </div>
              )}

              < button onClick={handleCancelResponse} className={styles.closeBtnResponse}> &times;</button>
              </div>
        )}

        {/* contenedor principal de un mensaje */}
        <div className={styles.chatInputContainer}>
          <div className={styles.chatInput}>
            {!visibleMicrophoneHandler && (
              <div 
                style={{width:"100%", display:"flex", alignItems:"center"}}
              >
                <TextAreaInputMessage
                  setIsNavigationActive={setIsNavigationActive}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                  selectedShortcut={selectedShortcut}
                  setSelectedShortcut={setSelectedShortcut} 
                  isDragging={isDragging}
                  setIsDragging={setIsDragging}
                  filteredShortcuts={filteredShortcuts}
                  setFilteredShortcuts={setFilteredShortcuts}
                  showShortcuts={showShortcuts}setShowShortcuts={setShowShortcuts}
                />
                <div>
                  { loadingMediaDevices ? 
                    <span className={styles.loaderSpinner}>
                      <LoaderSpinner/> 
                    </span>
                    : (
                      <div className={styles.chatInputBtnsContainer}>
                       { textValue === '' && (<button
                              style={{
                                  marginLeft:"10px",
                                  display: "flex",
                                  backgroundColor: "transparent",
                                  color: "inherit",
                                  border: "none",
                                  boxShadow: "none",
                                  cursor: "pointer",
                                  fontSize: "21px",
                                  alignItems: "center", // Para centrar el icono dentro del botón
                                  justifyContent: "center"
                              }}
                              onClick={startHandle}
                              className=""
                          >
                              <BsFillMicFill style={{ fontSize: "21px" }} />
                          </button>
                        )}
                            <TieredMenu model={menuItems} popup ref={menu} breakpoint="767px" />
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                color: "inherit",              
                                border: "none",                
                                boxShadow: "none",              
                                cursor: "pointer",              
                                fontSize: "25px"
                              }}
                              icon={<MdOutlineAddCircleOutline />}
                              onClick={(e, index) => optionMenu(e, index)}
                            />
                        </div>
                    )}
                </div>
              </div>
            )}

         
            {/* Reproductor de audio para escuchar la grabación */}
            {audioUrl && !voice  && (
              <div 
                style={{ width:"100%",display:"flex", alignItems:"center", gap:"6px"}}
              >
                
                <audio controls src={audioUrl} type="audio/webm" className="w-full" />
                <div 
                  className={styles.deleteAudioBtn} 
                >
                    <button onClick={clearHandle}>
                        <MdDelete
                            style={{
                                backgroundColor: "transparent",
                                color: "inherit",
                                border: "none",
                                boxShadow: "none",
                                cursor: "pointer",
                                fontSize: "25px",
                            }}
                        />
                    </button>
              </div>
              </div>
            )}
            
            {/* Componente para grabar audio  */}
            {visibleMicrophoneHandler && isRunning  && ( 
                <VoiceMediaRecorderBtn/>
            )}
              
          </div>
          {/* Botón de enviar mensaje */}
            <div className={styles.sendButtonContainer}>
            <ReactMic
                    record={voice}
                    className="sound-wave w-full hidden"
                    onStop={onStop}
                    strokeColor="#000000"
                    // backgroundColor="#FF4081"
                    mimeType='audio/mpeg'
                />
              <button className={styles.sendButton}
                  onClick={handleSendMessagge}       
                  disabled={globalLoading}
                >
                <BsSend className={styles.sendIcon} />
              </button>

            </div>
        </div>

        {/* contenedor de los listados de las imagenes y documentos */}
        <div>
          {imagePreviewUrls.length > 0 && (
            <div className={styles.imageContainer}>
              {imagePreviewUrls.map((url, index) => (
                <div key={index} className={styles.imagePreview}>
                  <img
                    src={url}
                    alt={`Vista previa ${index}`}
                  />
                  <button
                    className={styles.imageButton}
                    onClick={() => removeImage(index)}
                  >
                  <FaTimes />
                  </button>
                </div>
              ))}
            </div>
          )}
          {videoPreviewUrls.length > 0 && (
            <div className={styles.videoContainer}>
              {videoPreviewUrls.map((url, index) => (
                <div key={index} className={styles.videoPreview}>
                  <video
                    src={url}
                    controls
                    className={styles.videoElement}
                  />
                  <button
                    className={styles.videoButton}
                    onClick={() => removeVideo(index)}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
            </div>
          )}


          {documentFiles.length > 0 && (
            <div className={styles.documentContainer}>
              {documentFiles.map((doc, index) => (
                <div key={index} className={styles.documentItem}>
                  <span>
                    {doc.url.endsWith('.pdf') ? (
                      <FaFilePdf className={styles.pdfIcon} size={30} />
                    ) : (
                      <FaFileWord className={styles.wordIcon} size={30} />
                    )}
                  </span>
                  <span>{doc.name}</span>
                  <button
                    className={styles.documentButton}
                    onClick={() => removeDocument(index)}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      <Toast ref={toast} />

    </div>
  );
};

export default ChatInputMessage;

export const MenuItems = ({
  selectImages,
  selectVideos,
  selectDocuments,
}) => [
  {
    label: "Documento",
    icon: "pi pi-fw pi-file",
    command: () => {
      selectDocuments();
    },
  },
  {
    label: "Fotos",
    icon: "pi pi-fw pi-image ",
    command: () => {
      selectImages();
    },
  },
  {
    label: "Videos",
    icon: "pi pi-fw pi-video ",
    command: () => {
      selectVideos();
    },
  },
];
