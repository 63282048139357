import styles from './StatusLight.module.css';
import { Tooltip } from 'primereact/tooltip';


export default function StatusLight({color, tooltip}) {
  let lightColor = null
  switch (color) {
    case "green":
        lightColor = "--success-hard-color"
        break;
    case "orange":
        lightColor = "--warning-color"
        break; 
    case "red":
        lightColor = "--danger-color"
        break;
    case "blue":
        lightColor = "--blue-color"
        break;
    default:
        lightColor = "--dark-color"
        break;
  }

  return (
    <div className={styles.StatusLight}>
        <Tooltip target="#StatusLight" />
        <div 
            id='StatusLight' 
            className={styles.light} 
            style={{backgroundColor: `var(${lightColor})`}}
            data-pr-tooltip={tooltip}
            data-pr-position="right"
            data-pr-at="right+5 top"
            data-pr-my="left center-2"
        />
    </div>
  )
}
