import { Timestamp } from "firebase/firestore";

export function formatTimestamp(timestamp) {
  if (!(timestamp instanceof Timestamp)) {
    // console.error("Invalid Timestamp object");
    return null;
  }

  // Convierte el Timestamp a una instancia de Date
  const date = timestamp.toDate();

  // Formatea la fecha y la hora
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return date.toLocaleString("en-US", options); // Puedes cambiar el idioma según tus preferencias
}


export function formatTimestampToTime(timestamp) {
    if (!(timestamp instanceof Timestamp) || !timestamp) {
      // console.error("Invalid Timestamp object");
      return "";
    }
  
    // Convierte el Timestamp a una instancia de Date
    const date = timestamp.toDate();
  
    // Formatea la hora en formato de 12 horas
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Asegura que se use el formato de 12 horas
    };
  
    return date.toLocaleTimeString("en-US", options); // Cambia "en-US" si necesitas otro idioma
  }
  