import styles from "./WhatsAppCRM.module.css";
import SidebarMenu from "./SidebarMenu/SidebarMenu.jsx";
import ChatWindow from "./ChatWindow/ChatWindow.jsx";
import MessagesListWindow from "./MessagesListWindow/MessagesListWindow.jsx";
import useResize from "../../hooks/useResize.js";
import { CHATMESSAGE, MESSAGESLIST, SIDEBARMENU } from "./utils/CRMconstants.js";
import {  useChatMenu } from "./ChatWindow/ChatMenuContext.jsx";

function WhatsAppCRM() {

  const {
    currentView, isMobile, setIsMobile, isWidgetView
   } = useChatMenu();

  // Estado para manejar en qué vista estamos en mobile
  useResize(setIsMobile);
//  console.log("validacion", isMobile , currentView, isWidgetView)
  return (
    <div >
      {isWidgetView  ? (
         // Para mobile mostramos según la vista actual
         <div className={styles.appContainerWidget}>
          {currentView === SIDEBARMENU && <SidebarMenu  />}
          {currentView === MESSAGESLIST && <MessagesListWindow  />}
          {currentView === CHATMESSAGE && <ChatWindow />}
        </div>
      ) 
       : isMobile ? 
         <div className={styles.appContainer}>
            {currentView === SIDEBARMENU && <SidebarMenu  />}
            {currentView === MESSAGESLIST && <MessagesListWindow  />}
            {currentView === CHATMESSAGE && <ChatWindow />}
          </div>
       : (
        // Pantalla responsive mostramos 
         <div className={styles.appContainer}>
          <SidebarMenu />
          <MessagesListWindow />
          <ChatWindow  />
      </div>
      )}
    </div>

  );
}

export default WhatsAppCRM;
