/* eslint-disable react/prop-types */
import StatusLight from "../../../../StatusLight/StatusLight";
import { useChatMenu } from "../../../ChatWindow/ChatMenuContext";
import styles from "./MessageListCard.module.css"




export default function MessageListCard({profile_image, wa_id, last_message_in_Date, last_message ="Ultimo mensaje...", isRead =false}) {
  const {
    handleMessageSelect,
   } = useChatMenu();

    return (
      <li 
        className={styles.messageCardContainer}
        onClick={() => handleMessageSelect(wa_id)}
        >
        <div className={styles.contactAvatar}>
          <img src={profile_image || "https://randomuser.me/api/portraits/lego/1.jpg"} alt="contact" />
        </div>
        <div className={styles.contactInfo}>
          <h4>{wa_id}</h4>
          <p>{last_message}</p>
        </div>
        <div className={styles.messageTime}>
          {last_message_in_Date}
        </div>
        <div className={styles.chatStatus}>
          <StatusLight color={!isRead ? "blue" : "none"}/>
        </div>
      </li>
    );
  }


